// react
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// third-party
import classNames from 'classnames';

import { cartUpdateLogin } from '../../store/cart';
// application
import AsyncAction from '../shared/AsyncAction';

function IndicatorCart(props) {
    const { cart, cartUpdateLogin } = props;

    let indicatorBadge;
    if (cart.loggedIn) indicatorBadge = cart.balance.toFixed(2) + '€';
    else indicatorBadge = 'Register';

    return (
        <div className="nav_shopcart">
            {cart.loggedIn && (
                <Link className="btn btn-primary" to="/shop/cart" style={{ background: '#333', border: '1px' }}>
                    {' '}
                    <button
                        type="button"
                        className={classNames('btn btn-sm btn-primary')}
                        style={{ background: 'rgba(255, 255, 255, 0.08)', color: '#eed5a5' }}
                    >
                        Balance: {indicatorBadge}
                    </button>
                </Link>
            )}{' '}
            {!cart.loggedIn && (
                <Link className="btn btn-primary" to="/account/register" style={{ background: '#333', border: '1px' }}>
                    {' '}
                    <button
                        type="button"
                        className={classNames('btn btn-sm btn-primary')}
                        style={{ background: 'rgba(255, 255, 255, 0.08)', color: '#eed5a5' }}
                    >
                        {indicatorBadge}
                    </button>
                </Link>
            )}
            <AsyncAction
                action={() => cartUpdateLogin(cart.loggedIn ? false : true)}
                render={({ loading, run }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('btn btn-sm btn-primary', {
                            'btn-loading': loading,
                        })}
                        style={{ background: 'rgba(255, 255, 255, 0.08)', color: '#eed5a5', marginTop: '5px' }}
                    >
                        {cart.loggedIn ? 'Log Out' : 'Log In'}
                    </button>
                )}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartUpdateLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
