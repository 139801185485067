// react
import React from 'react';
import { Link } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';

function AppLink(props) {
    const { children, external, ...otherProps } = props;
    let link;

    if (external) {
        const { innerRef, replace, to, ...linkProps } = otherProps;

        link = (
            <a href={to} {...linkProps}>
                {children}
            </a>
        );
    } else {
        link = <Link {...otherProps}>{children}</Link>;
    }

    return link;
}

AppLink.propTypes = {
    external: PropTypes.bool,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    replace: PropTypes.bool,
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string,
            state: PropTypes.any,
        }),
    ]).isRequired,
};
AppLink.defaultProps = {
    external: false,
};

export default AppLink;
