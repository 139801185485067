export default {
    count: 4,
    items: [
        {
            author: 'Jessica Moore',
            avatar: 'images/avatars/avatar-1.jpg',
            children: [
                {
                    author: 'Adam Taylor',
                    avatar: 'images/avatars/avatar-2.jpg',
                    date: 'December 4, 2018',
                    id: 2,
                    text:
                        'Ut vitae finibus nisl, suscipit porttitor urna. Integer efficitur efficitur velit non pulvinar. ' +
                        'Aliquam blandit volutpat arcu vel tristique. Integer commodo ligula id augue tincidunt faucibus.',
                },
                {
                    author: 'Helena Garcia',
                    avatar: 'images/avatars/avatar-3.jpg',
                    date: 'December 12, 2018',
                    id: 3,
                    text:
                        'Suspendisse dignissim luctus metus vitae aliquam. Vestibulum sem odio, ullamcorper a imperdiet a, ' +
                        'tincidunt sed lacus. Sed magna felis, consequat a erat ut, rutrum finibus odio.',
                },
            ],
            date: 'November 30, 2018',
            id: 1,
            text:
                'Aliquam ullamcorper elementum sagittis. Etiam lacus lacus, mollis in mattis in, vehicula eu nulla. ' +
                'Nulla nec tellus pellentesque.',
        },
        {
            author: 'Ryan Ford',
            avatar: 'images/avatars/avatar-4.jpg',
            date: 'December 5, 2018',
            id: 4,
            text: 'Nullam at varius sapien. Sed sit amet condimentum elit.',
        },
    ],
};
