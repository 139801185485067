export default [
    {
        title: 'Jackpots',
        url: '/shop/category-grid-3-columns-sidebar?cat=Jackpots',
    },
    {
        title: 'Slots',
        url: '/shop/category-grid-3-columns-sidebar?cat=Slots',
    },
    {
        title: 'Instant win',
        url: '/shop/category-grid-3-columns-sidebar?cat=Instant win',
    },

    {
        title: 'Table Games',
        url: '/shop/category-grid-3-columns-sidebar?cat=Table Games',
    },
];
