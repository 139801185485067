// react
import React from 'react';
// third-party
import { Link } from 'react-router-dom';

// data stubs
import brands from '../../data/shopBrands';
// application
import SlickWithPreventSwipeClick from '../shared/SlickWithPreventSwipeClick';

const slickSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToScroll: 5,
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToScroll: 4,
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 3,
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToScroll: 2,
                slidesToShow: 2,
            },
        },
    ],
    slidesToScroll: 6,
    slidesToShow: 6,
    speed: 400,
};

export default function BlockBrands() {
    const brandsList = brands.map((brand, index) => (
        <div key={index} className="block-brands__item">
            <Link to="/">
                <img src={brand.image} alt="" />
            </Link>
        </div>
    ));

    return (
        <div className="block block-brands">
            <div className="container">
                <div className="block-brands__slider">
                    <SlickWithPreventSwipeClick {...slickSettings}>{brandsList}</SlickWithPreventSwipeClick>
                </div>
            </div>
        </div>
    );
}
