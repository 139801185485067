import { toast } from 'react-toastify';

import {
    CART_ADD_ITEM,
    CART_REMOVE_ALL_ITEMS,
    CART_REMOVE_ITEM,
    CART_UPDATE_BALANCE,
    CART_UPDATE_DEPOSIT,
    CART_UPDATE_LOGIN,
    CART_UPDATE_QUANTITIES,
} from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Product "${product.name}" added to cart!`);

    return {
        options,
        product,
        quantity,
        type: CART_ADD_ITEM,
    };
}

export function cartUpdateLoginSuccess(loginState) {
    //window.location.reload();
    if (!window.dataLayer) window.dataLayer = [];
    if (loginState) {
        window.dataLayer.push({
            event: 'Login',
        });
    } else {
        window.dataLayer.push({
            event: 'Logout',
        });
    }
    return {
        loginState: loginState,
        type: CART_UPDATE_LOGIN,
    };
}

export function cartUpdateDepositSuccess(deposit) {
    return {
        deposit: deposit,
        type: CART_UPDATE_DEPOSIT,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        itemId,
        type: CART_REMOVE_ITEM,
    };
}

export function cartRemoveAllItemsSuccess() {
    return {
        type: CART_REMOVE_ALL_ITEMS,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        quantities,
        type: CART_UPDATE_QUANTITIES,
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 2000);
        });
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 2000);
        });
}

export function cartRemoveAllItems() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveAllItemsSuccess());
                resolve();
            }, 2000);
        });
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 2000);
        });
}

export function cartUpdateBalanceSuccess(deposit, product) {
    if (deposit < 0) {
        toast.warn(`${deposit.toFixed(2)}€ has been deducted!`);

        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        if (product) {
            window.dataLayer.push({
                event: 'gameSpin',
                products: [
                    {
                        amount: deposit,
                        category: product.category + '/' + product.subcategory,

                        gameId: product.id,
                        image: window.location.origin + product.images[0],
                        launchUrl: window.location.origin + '/shop/play/' + product.id,
                        name: product.name,
                        thumbnail: window.location.origin + product.images[0],
                        vendor: product.brand,
                    },
                ],
            });
        }
    } else if (deposit > 0) {
        toast.success(`Deposit ${deposit.toFixed(2)}€ success!`);
        if (!window.dataLayer) window.dataLayer = [];
        window.dataLayer.push({
            amount: deposit,
            event: 'deposit',
            option: 'Visa',
        });
    }

    return {
        deposit: deposit,
        type: CART_UPDATE_BALANCE,
    };
}

export function cartUpdateBalance(deposit, product) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateBalanceSuccess(deposit, product));
                resolve();
            }, 2000);
        });
}

export function cartUpdateLogin(loginState) {
    // sending request to server, timeout is used as a stub
    console.log('cartUpdateLogin ' + loginState);

    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateLoginSuccess(loginState));
                resolve();
            }, 2000);
        });
}

export function cartUpdateDeposit(deposit) {
    // sending request to server, timeout is used as a stub
    console.log('cartUpdateDeposit ' + deposit);

    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateDepositSuccess(deposit));
                resolve();
            }, 2000);
        });
}
