import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import classNames from 'classnames';

const DialogForm = (props) => {
    const { onClose, open, selectedValue, text } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={open}>
                <div style={{ backgroundColor: 'white', padding: '30px' }}>
                    {' '}
                    <h2>
                        {text}{' '}
                        <button
                            type="button"
                            className={classNames('btn btn-secondary')}
                            style={{ background: 'white', color: 'black', marginBottom: '8px' }}
                            onClick={handleClose}
                        >
                            <CancelIcon />
                        </button>
                    </h2>
                </div>
            </Dialog>
        </div>
    );
};
export default DialogForm;
