import React from 'react';

import PageHeader from '../shared/PageHeader';
import HandlePlay from '../shared/PlayAction';

import '../../scss/pages/_livecasino.scss';

const LiveCasino = () => {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Live casino', url: '' },
    ];

    return (
        <div>
            <div className="site_body">
                <PageHeader breadcrumb={breadcrumb} />

                <div className="container">
                    <div className="livecasino_flex-container">
                        <div className="flex-item-left-livecasino">
                            <div className="flex-item-image_container">
                                <img src="images/live-casino.jpg" alt="" />
                            </div>
                        </div>
                        <div className="flex-item-right-livecasino">
                            <h1>Live Casino</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit suscipit mi,
                                non tempor nulla finibus eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </p>
                            <div className="blackjack_button">
                                <HandlePlay textPlay="Play" />
                            </div>
                            <div id="frosmo-livecasino-reco-1"></div>
                            <div id="frosmo-livecasino-reco-2"></div>
                            <div id="frosmo-livecasino-reco-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveCasino;
