// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sidebarOpen } from '../../store/sidebar';
import { LayoutGrid16x16Svg, LayoutGridWithDetails16x16Svg, LayoutList16x16Svg } from '../../svg';
// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';

class ProductsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        };
    }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };

    render() {
        const { grid, layout: propsLayout, offcanvas, products, sidebarOpen } = this.props;
        const { layout: stateLayout, page } = this.state;
        const layout = stateLayout || propsLayout;

        let viewModes = [
            { icon: <LayoutGrid16x16Svg />, key: 'grid', title: 'Grid' },
            { icon: <LayoutGridWithDetails16x16Svg />, key: 'grid-with-features', title: 'Grid With Features' },
            { icon: <LayoutList16x16Svg />, key: 'list', title: 'List' },
        ];

        viewModes = viewModes.map((viewMode) => {
            const className = classNames('layout-switcher__button', {
                'layout-switcher__button--active': layout === viewMode.key,
            });

            return (
                <button
                    key={viewMode.key}
                    title={viewMode.title}
                    type="button"
                    className={className}
                    onClick={() => this.setLayout(viewMode.key)}
                >
                    {viewMode.icon}
                </button>
            );
        });

        const productsList = products.map((product) => (
            <div key={product.id} className="products-list__item">
                <ProductCard product={product} />
            </div>
        ));

        const viewOptionsClasses = classNames('view-options', {
            'view-options--offcanvas--always': offcanvas === 'always',
            'view-options--offcanvas--mobile': offcanvas === 'mobile',
        });

        return (
            <div className="products-view">
                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">{productsList}</div>
                </div>

                <div className="products-view__pagination">
                    <Pagination current={page} siblings={2} total={2} onPageChange={this.handlePageChange} />
                </div>
            </div>
        );
    }
}

ProductsView.propTypes = {
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),

    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),

    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),

    /**
     * array of product objects
     */
    products: PropTypes.array,
};

ProductsView.defaultProps = {
    grid: 'grid-3-sidebar',
    layout: 'grid',
    offcanvas: 'mobile',
    products: [],
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
