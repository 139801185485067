export default [
    {
        address: 'Sun Orbit, 43.3241-85.239',
        city: 'MarsGrad',
        country: 'RandomLand',
        default: false,
        email: 'dd@example.com',
        firstName: 'Dennis',
        id: 1,
        lastName: 'Demoer',
        phone: 'ZX 971 972-57-26',
        postcode: '4b4f53',
    },
    {
        address: '2nd Street, 152178',
        city: 'Springfield',
        country: 'Random Republic',
        default: true,
        email: 'hg@example.com',
        firstName: 'Helena',
        id: 2,
        lastName: 'Garcia',
        phone: '38 972 588-42-36',
        postcode: '115302',
    },
];
