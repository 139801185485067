import React from 'react';

import HandlePlay from '../shared/PlayAction';

const BetPlate = ({ cart, cartUpdateBalance }) => {
    const randomNumber = (max, min) => {
        return (Math.random() * (max - min) + min).toFixed(1);
    };

    return (
        <ul className="sports__meta_ul_data">
            <li className="sports__meta_data_li">
                {randomNumber(4, 1)} <span>1</span>
            </li>
            <li className="sports__meta_data_li">
                {randomNumber(4, 1)} <span>X</span>
            </li>
            <li className="sports__meta_data_li">
                {randomNumber(4, 1)} <span>2</span>
            </li>
            <li>
                <HandlePlay textPlay="Bet" />
            </li>
        </ul>
    );
};

export default BetPlate;
