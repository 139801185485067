// react
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// third-party
import PropTypes from 'prop-types';

// data stubs
import theme from '../data/theme';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import LiveCasino from './liveCasino/LiveCasino';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';
import PageCart from './shop/ShopPageCart';
import ShopPageCategory from './shop/ShopPageCategory';
import PageCheckout from './shop/ShopPageCheckout';
import PageCompare from './shop/ShopPageCompare';
import ShopPagePlay from './shop/ShopPagePlay';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import PageWishlist from './shop/ShopPageWishlist';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageComponents from './site/SitePageComponents';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePageTypography from './site/SitePageTypography';
import ThankYou from './site/ThankYou';
import Sports from './sports/Sports';
// application
import Footer from './footer';
import Header from './header';

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
            return decodeURI(pair[1]);
        }
    }
    return false;
}

function getOpenGraphPageType() {
    var og = 'page.generic';
    const pathname = window.location.pathname;

    if (pathname.indexOf('/shop/category') === 0) {
        og = 'page.category';
    } else if (pathname.indexOf('/shop/play') === 0 || pathname.indexOf('/shop/demoplay') === 0) {
        og = 'page.game';
    } else if (pathname.length <= 1) {
        og = 'page.front';
    }
    return og;
}

function Layout(props) {
    const { headerLayout, homeComponent, match } = props;
    const productCategory = getQueryVariable('cat');
    const productSubCategory = getQueryVariable('subcat');
    const ogType = getOpenGraphPageType();

    console.log('og page type: ' + ogType);
    console.log('Category: ' + getQueryVariable('cat'));
    console.log('Sub-Category: ' + getQueryVariable('subcat'));

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
                <meta property="og:type" content={ogType} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />
                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/category-grid-3-columns-sidebar" />

                        <Route
                            exact
                            path="/shop/category-grid-3-columns-sidebar"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    productCategory={productCategory}
                                    productSubCategory={productSubCategory}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/category-grid-4-columns-full"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={4}
                                    viewMode="grid"
                                    productCategory={productCategory}
                                    productSubCategory={productSubCategory}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/category-grid-5-columns-full"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={5}
                                    viewMode="grid"
                                    productCategory={productCategory}
                                    productSubCategory={productSubCategory}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/category-list"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="list"
                                    sidebarPosition="start"
                                    productCategory={productCategory}
                                    productSubCategory={productSubCategory}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/category-right-sidebar"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="end"
                                    productCategory={productCategory}
                                    productSubCategory={productSubCategory}
                                />
                            )}
                        />

                        <Route exact path="/shop/product/:productId" component={ShopPageProduct} />
                        <Route
                            exact
                            path="/shop/product-standard"
                            render={(props) => <ShopPageProduct {...props} layout="standard" />}
                        />
                        <Route
                            exact
                            path="/shop/product-columnar"
                            render={(props) => <ShopPageProduct {...props} layout="columnar" />}
                        />
                        <Route
                            exact
                            path="/shop/product-sidebar"
                            render={(props) => <ShopPageProduct {...props} layout="sidebar" />}
                        />

                        <Route exact path="/sports" component={Sports} />
                        <Route exact path="/liveCasino" component={LiveCasino} />
                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/checkout" component={PageCheckout} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />

                        {/*
                        // Blog
                        */}
                        <Redirect exact from="/blog" to="/blog/category-classic" />
                        <Route
                            exact
                            path="/blog/category-classic"
                            render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-grid"
                            render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-list"
                            render={(props) => <BlogPageCategory {...props} layout="list" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/category-left-sidebar"
                            render={(props) => <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />}
                        />
                        <Route
                            exact
                            path="/blog/post-classic"
                            render={(props) => <BlogPagePost {...props} layout="classic" sidebarPosition="end" />}
                        />
                        <Route
                            exact
                            path="/blog/post-full"
                            render={(props) => <BlogPagePost {...props} layout="full" />}
                        />
                        <Route exact path="/shop/demoplay/:productId" component={ShopPagePlay} />
                        <Route exact path="/shop/play/:productId" component={ShopPagePlay} />

                        {/*
                        // Account
                        */}
                        <Route exact path="/account/login" component={AccountPageLogin} />
                        <Route exact path="/account/register" component={AccountPageLogin} />
                        <Route path="/account" component={AccountLayout} />

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={SitePageAboutUs} />
                        <Route exact path="/site/components" component={SitePageComponents} />
                        <Route exact path="/site/contact-us" component={SitePageContactUs} />
                        <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/faq" component={SitePageFaq} />
                        <Route exact path="/site/terms" component={SitePageTerms} />
                        <Route exact path="/site/typography" component={SitePageTypography} />
                        <Route exact path="/site/thankyou" component={ThankYou} />

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
