// react
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';

// application
import messages from '../i18n';

import HomePageOne from './home/HomePageOne';
import HomePageTwo from './home/HomePageTwo';
// pages
import Layout from './Layout';
// scroll to top on page change
import RouteChangeMonitor from './RouteChangeMonitor';

class Root extends Component {
    componentDidMount() {
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);
    }

    render() {
        const { locale } = this.props;
        console.log('process URL is', process.env.PUBLIC_URL);
        return (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route
                            path="/home-two"
                            render={(props) => <Layout {...props} headerLayout="compact" homeComponent={HomePageTwo} />}
                        />
                        <Route
                            path="/"
                            render={(props) => <Layout {...props} headerLayout="default" homeComponent={HomePageOne} />}
                        />
                        <Redirect to="/" />
                    </Switch>
                    <RouteChangeMonitor />
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Root);
