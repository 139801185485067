// react
import React from 'react';
import { Link } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';

import HandlePlay from './PlayAction';
// application
import ProductGallery from './ProductGallery';
import Rating from './Rating';

const Play = ({ demo, layout, product }) => {
    if (!window.dataLayer) window.dataLayer = [];
    window.dataLayer.push({
        event: 'gamePlay',
        products: [
            {
                category: product.category + '/' + product.subcategory,
                gameId: product.id,
                image: window.location.origin + product.images[0],
                launchUrl: window.location.origin + '/shop/play/' + product.id,
                name: product.name,
                playType: demo ? 'demo' : 'real',
                thumbnail: window.location.origin + product.images[0],
                vendor: product.brand,
            },
        ],
    });

    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                <ProductGallery layout={layout} images={product.images} />

                <div className="product__info">
                    <h1 className="product__name">{product.name}</h1>
                    <div id="__product_category_path" className="hidden-div">
                        {product.category}/{product.subcategory}
                    </div>
                    <div id="__product_name" className="hidden-div">
                        {product.name}
                    </div>
                    <div id="__product_category" className="hidden-div">
                        {product.category}
                    </div>
                    <div id="__product_subcategory" className="hidden-div">
                        {product.subcategory}
                    </div>
                    <div id="__product_price" className="hidden-div">
                        {product.price}
                    </div>
                    <div id="__product_image_url" className="hidden-div">
                        {product.images[0]}
                    </div>
                    <div id="__product_id" className="hidden-div">
                        {product.id}
                    </div>
                    <div id="__product_url" className="hidden-div">
                        {'/shop/product/' + product.id}
                    </div>
                    <div className="product__rating">
                        <div className="product__rating-stars">
                            <Rating value={product.rating} />
                        </div>
                        <div className="product__rating-legend">
                            <Link to="/">{`${product.reviews} Reviews`}</Link>
                            <span>/</span>
                            <Link to="/">Write A Review</Link>
                        </div>
                    </div>
                    <div className="product__description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare
                        elementum, libero nibh lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius
                        nisi.
                    </div>
                    <ul className="product__meta">
                        <li>Game provider: {product.brand}</li>
                        {product.features ? <li>Features: {product.features}</li> : null}
                        <li>Theme: {product.theme}</li>
                        {product.reels ? <li>Reels: {product.reels}</li> : null}
                    </ul>
                    <ul className="product__meta">
                        <li>RTP: {product.rtp}</li>
                        <li>Volatility: {product.volatility}</li>
                    </ul>
                    <ul className="product__meta">
                        <li>Country: {product.country}</li>
                        <li>Juristriction: {product.juristriction}</li>
                        <li>Margin: {product.margin}</li>
                    </ul>
                    {demo === false ? (
                        <div>
                            <HandlePlay product={product} textPlay="Play" />
                        </div>
                    ) : (
                        ''
                    )}
                    <div id="frosmo-game-reco-1"></div>
                    <div id="frosmo-game-reco-2"></div>
                    <div id="frosmo-game-reco-3"></div>
                    <div id="frosmo-game-reco-4"></div>
                    <div id="frosmo-game-reco-5"></div>
                </div>
            </div>
        </div>
    );
};

Play.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),

    /** product object */
    product: PropTypes.object.isRequired,
};

Play.defaultProps = {
    layout: 'standard',
};

export default Play;
