export default [
    {
        label: 'Home',
        type: 'link',
        url: '/',
    },
    {
        children: [
            {
                children: [
                    { label: 'Drills', type: 'link', url: '/shop/category-list?cat=Power Tools&subcat=Drills' },
                    { label: 'Saws', type: 'link', url: '/shop/category-list?cat=Power Tools&subcat=Saws' },
                    {
                        label: 'Woodworking',
                        type: 'link',
                        url: '/shop/category-list?cat=Power Tools&subcat=Woodworking',
                    },
                ],
                label: 'Power Tools',
                type: 'link',
                url: '/shop/category-list?cat=Power Tools',
            },
            {
                children: [
                    {
                        label: 'Wrenches and Pliers',
                        type: 'link',
                        url: '/shop/category-list?cat=Hand Tools&subcat=Wrenches and Pliers',
                    },
                    { label: 'Kits', type: 'link', url: '/shop/category-list?cat=Hand Tools&subcat=Kits' },
                ],
                label: 'Hand Tools',
                type: 'link',
                url: '/shop/category-list?cat=Hand Tools',
            },
            {
                children: [
                    {
                        label: 'Chain saws',
                        type: 'link',
                        url: '/shop/category-list?cat=Machine Tools&subcat=Chain saws',
                    },
                    {
                        label: 'Large tools',
                        type: 'link',
                        url: '/shop/category-list?cat=Machine Tools&subcat=Large tools',
                    },
                ],
                label: 'Machine Tools',
                type: 'link',
                url: '/shop/category-list?cat=Machine Tools',
            },
            {
                children: [
                    { label: 'Compressors', type: 'link', url: '/shop/category-list?cat=Other&subcat=Compressors' },
                    { label: 'Plumbing', type: 'link', url: '/shop/category-list?cat=Other&subcat=Plumbing' },
                ],
                label: 'Other',
                type: 'link',
                url: '/shop/category-list?cat=Other',
            },
        ],
        label: 'Categories',
        type: 'link',
        url: '',
    },
    /*
    {
        type: 'link',
        label: 'Shop',
        url: '/shop/category-grid-3-columns-sidebar',
        children: [
            {
                type: 'link',
                label: 'Shop Grid',
                url: '/shop/category-grid-3-columns-sidebar',
                children: [
                    { type: 'link', label: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
                    { type: 'link', label: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
                    { type: 'link', label: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
                ],
            },
            { type: 'link', label: 'Shop List', url: '/shop/category-list' },
            { type: 'link', label: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
            {
                type: 'link',
                label: 'Product',
                url: '/shop/product-standard',
                children: [
                    { type: 'link', label: 'Product', url: '/shop/product-standard' },
                    { type: 'link', label: 'Product Alt', url: '/shop/product-columnar' },
                    { type: 'link', label: 'Product Sidebar', url: '/shop/product-sidebar' },
                ],
            },
            { type: 'link', label: 'Cart', url: '/shop/cart' },
            { type: 'link', label: 'Checkout', url: '/shop/checkout' },
            { type: 'link', label: 'Wishlist', url: '/shop/wishlist' },
            { type: 'link', label: 'Compare', url: '/shop/compare' },
            { type: 'link', label: 'Track Order', url: '/shop/track-order' },
        ],
    },
*/
    {
        children: [
            { label: 'Login', type: 'link', url: '/account/login' },
            { label: 'Dashboard', type: 'link', url: '/account/dashboard' },
            { label: 'Edit Profile', type: 'link', url: '/account/profile' },
            { label: 'Order History', type: 'link', url: '/account/orders' },
            { label: 'Address Book', type: 'link', url: '/account/addresses' },
            { label: 'Change Password', type: 'link', url: '/account/password' },
        ],
        label: 'Account',
        type: 'link',
        url: '/account',
    },

    {
        children: [
            { label: 'Blog Classic', type: 'link', url: '/blog/category-classic' },
            { label: 'Blog Grid', type: 'link', url: '/blog/category-grid' },
            { label: 'Blog List', type: 'link', url: '/blog/category-list' },
            { label: 'Blog Left Sidebar', type: 'link', url: '/blog/category-left-sidebar' },
            { label: 'Post Page', type: 'link', url: '/blog/post-classic' },
            { label: 'Post Without Sidebar', type: 'link', url: '/blog/post-full' },
        ],
        label: 'Blog',
        type: 'link',
        url: '/blog/category-classic',
    },

    {
        children: [
            { label: 'About Us', type: 'link', url: '/site/about-us' },
            { label: 'Contact Us', type: 'link', url: '/site/contact-us' },
            { label: 'Contact Us Alt', type: 'link', url: '/site/contact-us-alt' },
            { label: '404', type: 'link', url: '/site/not-found' },
            { label: 'Terms And Conditions', type: 'link', url: '/site/terms' },
            { label: 'FAQ', type: 'link', url: '/site/faq' },
            { label: 'Components', type: 'link', url: '/site/components' },
            { label: 'Typography', type: 'link', url: '/site/typography' },
        ],
        label: 'Pages',
        type: 'link',
        url: '/site/about-us',
    },

    {
        children: [
            { data: { code: 'EUR', type: 'currency' }, label: '€ Euro', type: 'button' },
            { data: { code: 'GBP', type: 'currency' }, label: '£ Pound Sterling', type: 'button' },
            { data: { code: 'USD', type: 'currency' }, label: '$ US Dollar', type: 'button' },
        ],
        label: 'Currency',
        type: 'button',
    },

    {
        children: [{ data: { locale: 'en', type: 'language' }, label: 'English', type: 'button' }],
        label: 'Language',
        type: 'button',
    },
];
