import React from 'react';
import PixIcon from '@mui/icons-material/Pix';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import StreamIcon from '@mui/icons-material/Stream';

import PageHeader from '../shared/PageHeader';

import BetPlate from './BetPlate';

import '../../scss/pages/_sports.scss';

const Sports = () => {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Sports', url: '' },
    ];

    return (
        <div className="site_body">
            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="sports_flex-container">
                    <div className="flex-item-left">
                        <div className="flex-item-image_container">
                            <img src="images/sports.jpg" alt="" />
                        </div>
                    </div>
                    <div className="flex-item-right">
                        <h1> Sportsbook</h1>
                        <div id="frosmo-sports-reco-1"></div>
                        <div id="frosmo-sports-reco-2"></div>
                        <div id="frosmo-sports-reco-3"></div>
                        <div className="sports_meta">
                            <div className="sports_meta_container">
                                <p>
                                    <SportsFootballIcon /> American Football
                                </p>

                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">New York Challengers</li>
                                        </ul>
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">California Giants</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Today at 20:00</ul>
                                    </div>

                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>

                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Florida Gators</li>
                                        </ul>
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Texas Tanks</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Today at 18:00</ul>
                                    </div>
                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>
                            </div>

                            <div className="sports_meta_container">
                                <p>
                                    <SportsHockeyIcon /> Ice Hockey
                                </p>
                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <StreamIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li"> Tampere Tigers</li>
                                        </ul>

                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <StreamIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Lahti Lions</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Live</ul>
                                    </div>
                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>
                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Seinajoki Stars</li>
                                        </ul>
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Helsinki Kings</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Today at 22:00</ul>
                                    </div>
                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>
                            </div>
                            <div className="sports_meta_container">
                                <p>
                                    <SportsCricketIcon /> Cricket
                                </p>
                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <StreamIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">Australia</li>
                                        </ul>

                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <StreamIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">England</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Live</ul>
                                    </div>
                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>
                                <div className="sports_ul_container">
                                    <div className="sports__meta_ul_left">
                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">India</li>
                                        </ul>

                                        <ul className="sports__meta_ul">
                                            <li className="sports__meta_li">
                                                <PixIcon fontSize="small" />
                                            </li>
                                            <li className="sports__meta_li">New zealand</li>
                                        </ul>
                                        <ul className="sports__meta_ul_data_time">Today at 12:00</ul>
                                    </div>
                                    <div className="sports__meta_ul_right">
                                        <BetPlate />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Sports;
