// react
import React, { Component } from 'react';
// third-party
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import PostCard from '../shared/PostCard';
import SlickWithPreventSwipeClick from '../shared/SlickWithPreventSwipeClick';

const slickSettings = {
    'grid-nl': {
        arrows: false,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
            },
        ],
        slidesToScroll: 3,
        slidesToShow: 3,
        speed: 400,
    },
    'list-sm': {
        arrows: false,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
            },
        ],
        slidesToScroll: 2,
        slidesToShow: 2,
        speed: 400,
    },
};

export default class BlockPosts extends Component {
    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { layout, posts, title } = this.props;

        const postsList = posts.map((post) => <PostCard key={post.id} post={post} />);

        return (
            <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
                <div className="container">
                    <BlockHeader arrows title={title} onNext={this.handleNextClick} onPrev={this.handlePrevClick} />

                    <div className="block-posts__slider">
                        <SlickWithPreventSwipeClick ref={this.setSlickRef} {...slickSettings[layout]}>
                            {postsList}
                        </SlickWithPreventSwipeClick>
                    </div>
                </div>
            </div>
        );
    }
}

BlockPosts.propTypes = {
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
    posts: PropTypes.array,
    title: PropTypes.string.isRequired,
};

BlockPosts.defaultProps = {
    layout: 'list-sm',
    posts: [],
};
