// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet';

import products from '../../data/shopProducts';
import theme from '../../data/theme';
// blocks
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';

function HomePageOne() {
    const columns = [
        {
            products: products.slice(0, 3),
            title: 'Top Rated',
        },
        {
            products: products.slice(3, 6),
            title: 'Interesting',
        },
        {
            products: products.slice(6, 9),
            title: 'Trending',
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home Page One — ${theme.name}`}</title>
            </Helmet>

            <BlockTabbedProductsCarousel title="Featured Games" layout="grid-4" />

            <BlockProductColumns columns={columns} />
        </React.Fragment>
    );
}

export default HomePageOne;
