// react
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// third-party
import classNames from 'classnames';

// data stubs
import theme from '../../data/theme';
import { cartRemoveAllItems, cartRemoveItem, cartUpdateBalance, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';
// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';

class ThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
    }

    render() {
        const { cart, state } = this.props;

        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Make Deposit', url: '' },
            { title: 'Thank You', url: '' },
        ];

        let content;

        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your deposit is complete</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Thank You — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Thank You" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveAllItems,
    cartRemoveItem,
    cartUpdateBalance,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
