// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterNewsletter from './FooterNewsletter';

export default function Footer() {
    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by{' '}
                        <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
                            React
                        </a>{' '}
                    </div>
                    <div className="site-footer__payments"></div>
                </div>
            </div>
        </div>
    );
}
