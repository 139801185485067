import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

let previousPathname = null;
let previousSearch = null;

export default function RouteChangeMonitor() {
    const { pathname } = useLocation();
    const { search } = useLocation();

    useEffect(() => {
        if (previousPathname || previousSearch) {
            window.scrollTo(0, 0);
            window.dataLayer.push({
                event: 'route_change',
                pathname: pathname,
                previousPathname: previousPathname,
                previousSearch: previousSearch,
                search: search,
            });
        }
        previousPathname = pathname;
        previousSearch = search;
    }, [pathname, search]);

    return null;
}
