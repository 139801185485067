export default [
    {
        author: 'Samantha Smith',
        avatar: 'images/avatars/avatar-1.jpg',
        date: '27 May, 2018',
        rating: 4,
        text:
            'Phasellus id mattis nulla. Mauris velit nisi, imperdiet vitae sodales in, maximus ut lectus. Vivamus commodo ' +
            'scelerisque lacus, at porttitor dui iaculis id. Curabitur imperdiet ultrices fermentum.',
    },
    {
        author: 'Adam Taylor',
        avatar: 'images/avatars/avatar-2.jpg',
        date: '12 April, 2018',
        rating: 3,
        text:
            'Aenean non lorem nisl. Duis tempor sollicitudin orci, eget tincidunt ex semper sit amet. Nullam neque justo, ' +
            'sodales congue feugiat ac, facilisis a augue. Donec tempor sapien et fringilla facilisis. Nam maximus consectetur ' +
            'diam. Nulla ut ex mollis, volutpat tellus vitae, accumsan ligula.',
    },
    {
        author: 'Helena Garcia',
        avatar: 'images/avatars/avatar-3.jpg',
        date: '2 January, 2018',
        rating: 5,
        text: 'Duis ac lectus scelerisque quam blandit egestas. Pellentesque hendrerit eros laoreet suscipit ultrices.',
    },
];
