// react
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// third-party
import classNames from 'classnames';

// data stubs
import theme from '../../data/theme';
//import { cartUpdateQuantities, cartUpdateDeposit, cartUpdateBalance } from '../../store/cart';
import { cartUpdateBalance } from '../../store/cart';
import { cartUpdateQuantities } from '../../store/cart';
import { cartUpdateDeposit } from '../../store/cart';
// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deposit: 500,
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }
            return {
                quantities: state.quantities,
            };
        });
    };

    getDeposit() {
        const { deposit } = this.state;
        console.log('getDeposit ' + deposit);
        return deposit;
    }

    handleChangeDeposit = (newDeposit) => {
        this.state.deposit = newDeposit;
        this.setState(this.state);

        console.log('handleChangeDeposit ' + newDeposit);
        console.log(this.state);
    };

    cartNeedUpdate() {
        console.log('cart need update');
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value;
            }).length > 0
        );
    }

    renderItems() {
        return (
            <tr className="cart-table__row">
                <td className="cart-table__column cart-table__column--id">Deposit funds from 4567 89** **** ****</td>
                <td className="cart-table__column cart-table__column--image">
                    <img src="/images/visa.jpg" />
                </td>

                <td className="cart-table__column cart-table__column--quantity" data-title="Deposit">
                    <InputNumber
                        onChange={(deposit) => this.handleChangeDeposit(deposit)}
                        value={this.getDeposit()}
                        min={1}
                        step={10}
                    />
                </td>
                <td className="cart-table__column cart-table__column--total" data-title="Total">
                    EUR
                </td>
            </tr>
        );
    }

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            let calcShippingLink;

            if (extraLine.type === 'shipping') {
                calcShippingLink = (
                    <div className="cart__calc-shipping">
                        <Link to="/">Calculate Shipping</Link>
                    </div>
                );
            }

            return (
                <tr key={index}>
                    <th>{extraLine.title}</th>
                    <td>
                        <Currency value={extraLine.price} />
                        {calcShippingLink}
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">{extraLines}</tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { deposit } = this.state;
        // this was it!!!
        const { cartUpdateBalance } = this.props;

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--id">Action</th>
                                <th className="cart-table__column cart-table__column--image">Payment method</th>
                                <th className="cart-table__column cart-table__column--product">Deposit amount</th>
                                <th className="cart-table__column cart-table__column--price">Currency</th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>

                    <div id="frosmo-cart-reco-slot" className="container"></div>
                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Deposit funds</h3>
                                    <table className="cart__totals">
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={deposit} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <AsyncAction
                                        action={() => cartUpdateBalance(deposit)}
                                        render={({ loading, run }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                className={classNames('btn btn-xl btn-block btn-primary', {
                                                    'btn-loading': loading,
                                                })}
                                                style={{ background: 'white', color: 'black' }}
                                            >
                                                Do it!
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Make deposit', url: '' },
        ];

        let content;

        content = this.renderCart();

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Make deposit — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Make deposit" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    balance: state.cart.balance,
    cart: state.cart,
});

const mapDispatchToProps = {
    cartUpdateBalance,
    cartUpdateDeposit,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
