// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// data stubs
import theme from '../../data/theme';
import { Check9x7Svg } from '../../svg';
// application
import PageHeader from '../shared/PageHeader';

export default function AccountPageLogin() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    function sendEvent() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'registrationSuccess',
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register</h3>

                                    <div className="form-group">
                                        <label htmlFor="register-email">Name</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-email">Address</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter address"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-email">Zip and City</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter zip and city"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-email">Country</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter country"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-email">Credit card number</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter credit card number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-email">Email address</label>
                                        <input
                                            id="register-email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-password">Password</label>
                                        <input
                                            id="register-password"
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="register-confirm">Repeat Password</label>
                                        <input
                                            id="register-confirm"
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <Link to={`/`}>
                                        <button
                                            onClick={sendEvent}
                                            type="button"
                                            className={classNames('btn btn-secondary')}
                                        >
                                            Register
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
