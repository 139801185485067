export default [
    {
        image: 'images/products/product-1.jpg',
        products: 572,
        subcategories: [
            { title: 'Drills', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Drills' },
            { title: 'Saws', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Saws' },
            { title: 'Woodworking', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Woodworking' },
        ],
        title: 'Power Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools',
    },
    {
        image: 'images/products/product-16.jpg',
        products: 134,
        subcategories: [
            {
                title: 'Wrenches and Pliers',
                url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Wrenches and Pliers',
            },
            { title: 'Kits', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Kits' },
        ],
        title: 'Hand Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools',
    },
    {
        image: 'images/products/product-6.jpg',
        products: 301,
        subcategories: [
            { title: 'Chain saws', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Chain saws' },
            { title: 'Large tools', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Large tools' },
        ],
        title: 'Machine Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools',
    },
    {
        image: 'images/products/product-14.jpg',
        products: 79,
        subcategories: [
            { title: 'Compressors', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Compressors' },
            { title: 'Plumbing', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Plumbing' },
        ],
        title: 'Other',
        url: '/shop/category-grid-3-columns-sidebar?cat=Other',
    },
];
