// react
import React from 'react';
import { Helmet } from 'react-helmet';
// third-party
import PropTypes from 'prop-types';

import products from '../../data/shopProducts';
// data stubs
import categories from '../../data/shopWidgetCategories';
import theme from '../../data/theme';
// application
import PageHeader from '../shared/PageHeader';
import Play from '../shared/Play';
// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

function ShopPagePlay(props) {
    const { layout, match, sidebarPosition } = props;
    let product;
    let demo = false;

    if (match.params.productId) {
        product = products.find((x) => x.id === parseFloat(match.params.productId));
    } else {
        product = products[products.length - 1];
    }

    let breadcrumb = [
        { title: 'Home', url: '' },
        { title: product.category, url: '' },
        { title: product.subcategory, url: '' },
        { title: 'Play', url: '' },
        { title: product.name, url: '' },
    ];

    if (window.location.href.indexOf('/demoplay') > -1) {
        demo = true;
        breadcrumb = [
            { title: 'Home', url: '' },
            { title: product.category, url: '' },
            { title: product.subcategory, url: '' },
            { title: 'Demo Play', url: '' },
            { title: product.name, url: '' },
        ];
    }

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={products.slice(0, 5)} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Play product={product} layout={layout} demo={demo} />
                        </div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Play product={product} layout={layout} demo={demo} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPagePlay.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPagePlay.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPagePlay;
