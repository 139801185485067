// react
import React from 'react';
import { Link } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';

// application
import { ArrowRoundedRight6x9Svg } from '../../svg';

function PageHeader(props) {
    let { breadcrumb, header } = props;

    if (header) {
        header = (
            <div className="page-header__title">
                <h1>{header}</h1>
            </div>
        );
    }

    if (breadcrumb.length > 0) {
        const lastIndex = breadcrumb.length - 1;

        breadcrumb = breadcrumb.map((item, index) => {
            let link;

            link = (
                <li key={index} className="breadcrumb-item active" aria-current="page">
                    {item.title}
                </li>
            );

            return link;
        });

        breadcrumb = (
            <div className="page-header__breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">{breadcrumb}</ol>
                </nav>
            </div>
        );
    }

    return (
        <div className="page-header">
            <div className="page-header__container container">
                {breadcrumb}
                {header}
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    /** array of breadcrumb links */
    breadcrumb: PropTypes.array,

    /** page header */
    header: PropTypes.node,
};

PageHeader.defaultProps = {
    breadcrumb: [],
};

export default PageHeader;
