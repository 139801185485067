import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { cartUpdateBalance } from '../../store/cart';
import DialogForm from '../shared/Dialog';
const HandlePlay = ({ cart, cartUpdateBalance, className, product, textPlay }) => {
    const [open, setOpen] = React.useState(false);
    const [buttonText, setButtonText] = React.useState(textPlay || 'Play');
    const buttonLoadingText = textPlay === 'Play' ? 'Playing' : '.';
    const text = `Please Log In first to ${buttonText}`;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleClickPlay = () => {
        let random = -Math.floor(Math.random() * 20);
        if (cart.balance + random < 0) random = -cart.deposit;
        if (cart.balance > 0) cartUpdateBalance(random, product);
        setButtonText(buttonLoadingText);
        setTimeout(() => setButtonText(`${buttonLoadingText}.`), 700);
        setTimeout(() => setButtonText(`${buttonLoadingText}..`), 1400);
        setTimeout(() => setButtonText(`${buttonLoadingText}...`), 2100);
        setTimeout(() => setButtonText(textPlay === 'Play' ? 'Play' : 'Bet'), 2800);
    };
    return (
        <div>
            <button
                type="button"
                className={textPlay === 'Play' ? 'button' : classNames('btn btn-secondary')}
                style={{ background: 'white', color: 'black', width: textPlay === 'Bet' ? '80px' : 'default' }}
                onClick={cart.loggedIn ? handleClickPlay : handleClickOpen}
            >
                {buttonText}
            </button>
            <DialogForm open={open} onClose={handleClose} cart={cart} text={text} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    balance: state.cart.balance,
    cart: state.cart,
});
const mapDispatchToProps = {
    cartUpdateBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandlePlay);
