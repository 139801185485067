// react
import React from 'react';
import { connect } from 'react-redux';
// third-party
import PropTypes from 'prop-types';

function Currency(props) {
    const { currency, currentCurrency, value } = props;
    const { symbol } = currency || currentCurrency;

    return <React.Fragment>{`${symbol}${value.toFixed(2)}`}</React.Fragment>;
}

Currency.propTypes = {
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,

    /** currency object */
    currentCurrency: PropTypes.object,

    /** price value */
    value: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
