// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { wishlistAddItem } from '../../store/wishlist';
import { Compare16Svg, Wishlist16Svg } from '../../svg';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const { cartAddItem, compareAddItem, layout, product, wishlistAddItem } = this.props;
        const { quantity } = this.state;
        let prices;

        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price">
                        <Currency value={product.price} />
                    </span>{' '}
                    <span className="product__old-price">
                        <Currency value={product.compareAtPrice} />
                    </span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product.images} />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ loading, run }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ loading, run }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>

                        <h1 className="product__name">{product.name}</h1>
                        <div id="__product_category_path" className="hidden-div">
                            {product.category}/{product.subcategory}
                        </div>
                        <div id="__product_name" className="hidden-div">
                            {product.name}
                        </div>
                        <div id="__product_category" className="hidden-div">
                            {product.category}
                        </div>
                        <div id="__product_subcategory" className="hidden-div">
                            {product.subcategory}
                        </div>
                        <div id="__product_price" className="hidden-div">
                            {product.price}
                        </div>
                        <div id="__product_image_url" className="hidden-div">
                            {product.images[0]}
                        </div>
                        <div id="__product_id" className="hidden-div">
                            {product.id}
                        </div>
                        <div id="__product_url" className="hidden-div">
                            {'/shop/product/' + product.id}
                        </div>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={product.rating} />
                            </div>
                            <div className="product__rating-legend">
                                <Link to="/">{`${product.reviews} Reviews`}</Link>
                                <span>/</span>
                                <Link to="/">Write A Review</Link>
                            </div>
                        </div>
                        <div className="product__description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare
                            elementum, libero nibh lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius
                            nisi.
                        </div>
                        <ul className="product__features">
                            <li>Speed: 750 RPM</li>
                            <li>Power Source: Cordless-Electric</li>
                            <li>Battery Cell Type: Lithium</li>
                            <li>Voltage: 20 Volts</li>
                            <li>Battery Capacity: 2 Ah</li>
                        </ul>
                        <ul className="product__meta">
                            <li>Game provider: Entnent</li>
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability: <span className="text-success">In Stock</span>
                        </div>
                    </div>

                    <div className="product__footer">
                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like">
                                    <Link to="/">Like</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--tweet">
                                    <Link to="/">Tweet</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--pin">
                                    <Link to="/">Pin It</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--counter">
                                    <Link to="/">4K</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),

    /** product object */
    product: PropTypes.object.isRequired,
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    compareAddItem,
    wishlistAddItem,
};

export default connect(() => ({}), mapDispatchToProps)(Product);
